import { UserGoals } from 'app';
import React from 'react';

export type ContentContextType = {
  getContent: (contentId: string) => any;
  setContent: (contentId: string, value: any) => void;
  getGoals: () => UserGoals | null;
  setGoals: (goals: UserGoals) => void;
};

export const ContentContext = React.createContext<ContentContextType>({
  getContent: (contentId: string) => null,
  setContent: (contentId: string, value: any) => {},
  getGoals: () => null,
  setGoals: (goals: UserGoals) => {},
});
